/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 111번째 로그에서는 클럽하우스 API 분석, AWS 도쿄리전 장애에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "클럽하우스 API 분석"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://theori.io/research/korean/analyzing-clubhouse/"
  }, "Analyzing Clubhouse for fun and profit - Theori")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.agora.io/en/"
  }, "Agora")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.pubnub.com/"
  }, "PubNub")), "\n"), "\n", React.createElement(_components.h3, null, "AWS 도쿄리전 장애"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--2021-02-20-aws-ap-northeast-1-outage"
  }, "AWS 도쿄 리전 장애 발생, 특정 AZ 일부 구역의 온도 상승이 원인 - 44BITS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://it.chosun.com/site/data/html_dir/2021/02/21/2021022100184.html"
  }, "쿠키런 킹덤, AWS 장애로 먹통 후 복구 - IT 조선")), "\n"), "\n", React.createElement(_components.h3, null, "AWS RDS 리플리케이션 필터링 지원"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2021/02/amazon-rds-mysql-mariadb-support-replication-filtering/"
  }, "Amazon RDS for MySQL 및 Amazon RDS for MariaDB, 복제 필터링 정식 지원 - AWS Blog")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
